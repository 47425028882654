import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { Global, css } from '@emotion/react';
import { NylasProvider } from '@nylas/nylas-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Head from 'next/head';
import { useRouter } from 'next/router';
import invariant from 'tiny-invariant';
import { AppLoadingWrapper } from '@/components/AppLoadingWrapper';
import { AuthWrapper } from '@/components/AuthWrapper';
import { UIContextProvider } from '@/components/ContextProvider';
import { SessionWrapper } from '@/components/SessionWrapper';
import * as fbq from '@/lib/fpixel';
import { useCurrentUser } from '@/stores/CurrentUserStore';
import withAuthRedirect from '../client/hoc/withAuthRedirect';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: false,
    },
  },
});

const handleRouteChange = () => {
  fbq.pageview();
};

const FacebookPixel = ({ children }: PropsWithChildren) => {
  const router = useRouter();

  useEffect(() => {
    fbq.pageview();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <>{children}</>;
};

const GlobalStyle = ({ children }: PropsWithChildren) => (
  <>
    <Head>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
    </Head>

    <Global
      styles={css`
        html {
          scroll-behavior: smooth;
        }
        #__next {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }
      `}
    />

    {children}
  </>
);

const IntercomWidget = () => {
  const { user } = useCurrentUser();

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENABLE_INTERCOM === 'true' &&
      !!window.Intercom
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'c7dzrt9c',
        user_id: user?.userId ?? undefined,
        name: user?.name ?? undefined,
        email: user?.email ?? undefined,
        created_at: user?.dateCreated ?? undefined,
      });
    }
  }, [user]);

  return <></>;
};

const App = ({ Component, pageProps }: { Component: any; pageProps: any }) => {
  const ComponentWithAuthRedirect = withAuthRedirect(Component);

  invariant(
    process.env.NEXT_PUBLIC_API_BASE_URL,
    'NEXT_PUBLIC_API_BASE_URL is required'
  );

  return (
    <FacebookPixel>
      <NylasProvider serverBaseUrl={process.env.NEXT_PUBLIC_API_BASE_URL}>
        <QueryClientProvider client={queryClient}>
          <UIContextProvider>
            <AppLoadingWrapper>
              <AuthWrapper>
                <SessionWrapper>
                  <GlobalStyle />
                  <IntercomWidget />
                  <ComponentWithAuthRedirect {...pageProps} />
                </SessionWrapper>
              </AuthWrapper>
            </AppLoadingWrapper>
          </UIContextProvider>
        </QueryClientProvider>
      </NylasProvider>
    </FacebookPixel>
  );
};

export default App;
