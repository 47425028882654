import type { ReactNode } from 'react';
import type { ButtonProps, ModalProps } from '@chakra-ui/react';
import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export type ConfirmDialogProps = {
  cancelLabel?: string;
  colorScheme?: ButtonProps['colorScheme'];
  confirmLabel?: string;
  isProcessing?: boolean;
  size?: ModalProps['size'];
  title: string;
  warningMessage?: ReactNode;
  onCancel?: () => void;
  onConfirm: () => void;
};

export const ConfirmDialog = ({
  cancelLabel = 'Cancel',
  colorScheme = 'red',
  confirmLabel = 'Yes',
  isProcessing = false,
  size = 'md',
  title,
  warningMessage,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) => (
  <Modal
    closeOnOverlayClick={false}
    isCentered
    isOpen
    size={size}
    onClose={onCancel || (() => {})}
  >
    <ModalOverlay />

    <ModalContent>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <Flex direction="column" gap="4">
          {warningMessage || null}
        </Flex>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button onClick={onCancel}>{cancelLabel}</Button>

          <Button
            colorScheme={colorScheme}
            fontWeight="medium"
            isDisabled={isProcessing}
            isLoading={isProcessing}
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
