import { proxy, useSnapshot } from 'valtio';
import type { Customer } from 'types/Customer';

interface CurrentCustomerStore {
  customer: Customer;
}

export const setCurrentCustomer = (customer: Customer) =>
  (store.customer = customer);

const store = proxy<CurrentCustomerStore>();

export const useCurrentCustomer = () => useSnapshot(store);
