import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCustomer, updateCustomer } from 'src/client/api';
import type { Customer } from 'types/Customer';

export const useGetCustomer = ({
  customerId,
  enabled = true,
}: {
  customerId: number | 'current';
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: ['customer', customerId],
    queryFn: () => getCustomer({ customerId }),
    enabled,
  });

export const useUpdateCustomer = ({
  customerId,
}: {
  customerId: number | 'current';
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-customer', customerId],
    mutationFn: (updateValues: Partial<Customer>) =>
      updateCustomer({ customerId, ...updateValues }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', customerId]);
    },
  });
};
