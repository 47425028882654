import { proxy, useSnapshot } from 'valtio';
import type { User } from 'types/User';

interface CurrentUserStore {
  user: User;
}

export const setCurrentUser = (user: User) => (store.user = user);

const store = proxy<CurrentUserStore>();

export const useCurrentUser = () => useSnapshot(store);
