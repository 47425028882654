export interface EmailCredentials {
  accessToken: string;
}

export interface UserEmailAccount {
  userEmailAccountId: number;
  customerId: number;
  userId: number;
  email: string;
  emailAccountId: string;
  credentials: EmailCredentials;
}

export type NewUserEmailAccount = Omit<
  UserEmailAccount,
  'userEmailAccountId' | 'customerId'
>;

export enum UserEmailAccountStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  DEPRECATED = 'DEPRECATED',
}
