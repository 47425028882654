export enum CampaignAutomationTaskType {
  LINKEDIN_SCHEDULED_SEND = 'LINKEDIN_SCHEDULED_SEND',
  GET_CAMPAIGN_DATA = 'GET_CAMPAIGN_DATA',
}

export enum CampaignAutomationTaskStatus {
  NEW = 'NEW',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}
