import { Flex, Spinner } from '@chakra-ui/react';

export const LoadingState = () => (
  <Flex
    alignItems="center"
    backgroundColor="green.500"
    direction="column"
    justifyContent="center"
    minHeight="100vh"
  >
    <Spinner color="white" size="xl" speed="0.65s" />
  </Flex>
);
