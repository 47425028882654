import type { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';
import { LoadingState } from '@/components/_common/LoadingState';
import { isAppReady, useAppReadyStore } from '@/stores/AppReadyStore';

export const AppLoadingWrapper = ({ children }: PropsWithChildren) => {
  const { readyStates } = useAppReadyStore();

  const isAppLoading = !isAppReady(readyStates);

  return (
    <>
      {isAppLoading && <LoadingState />}
      <Box display={isAppLoading ? 'none' : undefined}>{children}</Box>
    </>
  );
};
