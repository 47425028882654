import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { ReadyFlags, setReadyFlag } from '@/stores/AppReadyStore';
import {
  handleSignOut,
  setChromeExtensionToken,
  setIsLoading,
  setIsSignedIn,
  signInWithEmailAndPassword,
  signOut,
  useAuthStore,
} from '@/stores/AuthStore';
import { firebaseAuth } from '@/utils/firebase';
import { getCurrentUser } from '../api';

const FIREBASE_AUTH_TIMEOUT = 10; // in seconds

const clearIndexedDb = (dbName: string) =>
  new Promise<void>((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onerror = (event: Event) => {
      console.error('Error opening IndexedDB:', event);
      reject(event);
    };

    request.onsuccess = (event: Event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      const transaction = db.transaction(db.objectStoreNames, 'readwrite');

      transaction.oncomplete = () => {
        console.log('All Firebase object stores cleared');
        resolve();
      };

      transaction.onerror = (event: Event) => {
        console.error('Transaction error:', event);
        reject(event);
      };

      Array.from(db.objectStoreNames).forEach((storeName) => {
        const objectStore = transaction.objectStore(storeName);
        objectStore.clear();
      });
    };
  });

const clearFirebaseIndexedDBs = async () => {
  await clearIndexedDb('firebaseLocalStorageDb');
  await clearIndexedDb('firebase-heartbeat-database');
};

export const AuthWrapper = ({ children }: PropsWithChildren) => {
  const { isLoading } = useAuthStore();

  useEffect(() => {
    setReadyFlag(ReadyFlags.auth, !isLoading);
  }, [isLoading]);

  /* Firebase session management methods */
  useEffect(() => {
    // onIdTokenChanged is triggered both on initial auth and for live updates to user
    const unsubscribe = firebaseAuth.onIdTokenChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        await handleSignOut();
      } else {
        const token = await firebaseUser.getIdToken();
        if (token === null) {
          Sentry.captureMessage('Firebase Token is null (onIdTokenChanged)');
        }

        try {
          const user = await getCurrentUser();

          if (user?.isEmailVerified) {
            setIsSignedIn(true);
            await setChromeExtensionToken(token);
          }
        } catch (error) {
          console.log(
            'signOut - onIdTokenChanged',
            error as Error,
            (error as Error).message
          );
          await signOut();
        }
      }

      setIsLoading(false);
      clearTimeout(timeoutId);
    });

    // fallback timeout to prevent the loading screen from showing forever
    const timeoutId = setTimeout(async () => {
      Sentry.captureMessage(
        'Firebase Auth Timeout (onIdTokenChanged) - resetting firebase state'
      );

      try {
        await clearFirebaseIndexedDBs();

        Sentry.captureMessage(
          'Firebase Auth Timeout (onIdTokenChanged) - cleared firebase indexedDBs'
        );

        window.location.reload();
      } catch (error) {
        Sentry.captureMessage(
          'Firebase Auth Timeout (onIdTokenChanged) - error clearing firebase state'
        );

        Sentry.captureException(error);
      }
    }, FIREBASE_AUTH_TIMEOUT * 1000);

    return () => {
      unsubscribe();
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    async function bypassAuth() {
      if (process.env.NEXT_PUBLIC_BYPASS_AUTH === '1') {
        if (
          !process.env.NEXT_PUBLIC_BYPASS_AUTH_EMAIL ||
          !process.env.NEXT_PUBLIC_BYPASS_AUTH_PASSWORD
        ) {
          throw new Error(
            'Missing NEXT_PUBLIC_BYPASS_AUTH_EMAIL or NEXT_PUBLIC_BYPASS_AUTH_PASSWORD'
          );
        }

        await signInWithEmailAndPassword({
          email: process.env.NEXT_PUBLIC_BYPASS_AUTH_EMAIL,
          password: process.env.NEXT_PUBLIC_BYPASS_AUTH_PASSWORD,
        });
      }
    }

    bypassAuth();
  }, []);

  // need to wait for token to set
  return isLoading ? null : <>{children}</>;
};
