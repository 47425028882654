import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { handleSignOut } from '@/stores/AuthStore';
import { firebaseAuth } from '@/utils/firebase';
import { convertISOToDates } from '@/utils/helpers';

const axiosWithToken = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

axiosWithToken.interceptors.request.use(
  async (config) => {
    const currentUser = firebaseAuth.currentUser;
    if (!currentUser) {
      Sentry.captureMessage('User session expired');

      await handleSignOut();
      return config;
    }

    const token = await currentUser.getIdToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosWithToken.interceptors.response.use((response) => {
  convertISOToDates(response.data);
  return response;
});

export { axiosWithToken };
