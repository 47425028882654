import type {
  ACTION_TYPE_EMAIL,
  ACTION_TYPE_LINKEDIN_CONNECTION_REQUEST,
  ACTION_TYPE_LINKEDIN_MESSAGE,
} from '../../constants';

export enum CampaignSendStatus {
  NEW = 'NEW',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type CampaignSendData = {
  [ACTION_TYPE_EMAIL]: number;
  [ACTION_TYPE_LINKEDIN_CONNECTION_REQUEST]: number;
  [ACTION_TYPE_LINKEDIN_MESSAGE]: number;
};

export interface CampaignSendMetricLog {
  campaignSendMetricLogId: number;
  customerId: number;
  userId: number;
  campaignId: number;
  dateRun: Date;
  status: CampaignSendStatus;
  scheduledData: CampaignSendData;
  resultData: CampaignSendData | null;
  dateCreated: Date;
  dateUpdated: Date;
}

export type NewCampaignSendMetricLog = Omit<
  CampaignSendMetricLog,
  'campaignSendMetricLogId' | 'customerId' | 'dateCreated' | 'dateUpdated'
> & {
  resultData?: CampaignSendData;
};
