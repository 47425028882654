import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { NewUserArgs } from 'src/client/api';
import {
  createUser,
  getCurrentUser,
  getUser,
  getUserList,
  updateUser,
} from 'src/client/api';
import type { User, UserListQuery } from 'types/User';

export const useGetUser = ({
  enabled = true,
  userId,
}: {
  enabled?: boolean;
  userId: number | 'current';
}) => {
  const queryFn =
    userId === 'current'
      ? getCurrentUser
      : () => getUser({ userId: Number(userId) });

  return useQuery({
    queryKey: ['user', userId === 'current' ? 'current' : userId],
    queryFn,
    enabled,
  });
};

export const useListUsers = ({
  enabled = true,
  ...params
}: { enabled?: boolean } & UserListQuery = {}) =>
  useQuery({
    queryKey: ['user-list', params],
    queryFn: () => getUserList(params),
    enabled,
  });

export const useUpdateUser = ({
  userId,
  isUpdatingCurrent = false,
}: {
  userId: number;
  isUpdatingCurrent?: boolean;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-user', userId],
    mutationFn: (updateValues: Partial<User>) =>
      updateUser({ userId, ...updateValues }),
    onSuccess: (_, updateValues) => {
      queryClient.invalidateQueries(['user', userId]);
      queryClient.invalidateQueries(['user-list']);

      if (isUpdatingCurrent) {
        queryClient.invalidateQueries(['user', 'current']);
      }

      if ('status' in updateValues) {
        queryClient.invalidateQueries(['customer', 'current']);
      }
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-user'],
    mutationFn: (user: NewUserArgs) => createUser({ user }),
    onSuccess: () => {
      queryClient.invalidateQueries(['user-list']);
      queryClient.invalidateQueries(['customer', 'current']);
    },
  });
};
