import { proxy, useSnapshot } from 'valtio';

export enum ReadyFlags {
  auth = 'auth',
  session = 'session',
}

interface AppReadyStore {
  readyStates: { [key in ReadyFlags]: boolean };
}

export const setReadyFlag = (flag: ReadyFlags, status = true) =>
  (store.readyStates[flag] = status);

// not pulling from store because we want changes to force a new check
export const isAppReady = (readyStates: AppReadyStore['readyStates']) =>
  Object.values(readyStates).every((flag) => flag === true);

const store = proxy<AppReadyStore>({
  readyStates: {
    auth: false,
    session: false,
  },
});

export const useAppReadyStore = () => useSnapshot(store);
