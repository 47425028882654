import { tabsAnatomy } from '@chakra-ui/anatomy';
import { cssVar, extendTheme } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const fonts = {
  body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
};

const colors = {
  talin: {
    orange: '#e2884d',
    lightGreen: '#99c199',
    green: '#2f855a',
  },
};

const $arrowBg = cssVar('popper-arrow-bg');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const overlayVariant = definePartsStyle(() => ({
  tab: {
    bg: 'gray.50',
    borderRadius: 'md',
    fontWeight: 'semibold',
    width: '100%',
    py: '1',
    _selected: {
      bg: 'white',
      boxShadow: 'sm',
      color: 'green.700',
      _hover: { cursor: 'default' },
    },
    color: 'gray.700',
  },
  tablist: {
    backgroundColor: 'gray.50',
    borderColor: 'gray.100',
    borderRadius: 'md',
    borderWidth: '1px',
    p: '1',
    px: '2',
  },
  tabpanel: {
    px: '0',
    py: '3',
  },
}));

const variants = {
  overlay: overlayVariant,
};

const tabsTheme = defineMultiStyleConfig({ variants });

export const theme = extendTheme({
  colors,
  fonts,
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: 'green.600',
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: 'green.600',
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: 'green',
      },
      variants: {
        setting: {
          track: {
            height: '20px',
            width: '34px',
          },
          thumb: {
            height: '20px',
            width: '20px',
          },
        },
      },
    },
    Tabs: tabsTheme,
    Textarea: {
      defaultProps: {
        focusBorderColor: 'green.600',
      },
    },
    Tooltip: {
      variants: {
        // Disabling hasArrow for semi opaque tooltips because it overlaps
        blurred: {
          [$arrowBg.variable]: 'none',
          arrowShadowColor: 'none',
          bg: 'blackAlpha.700',
          backdropFilter: 'blur(4px)',
          color: 'white',
          hasArrow: false,
        },
      },
    },
  },
  sizes: {
    fullscreen: '1440px',
  },
});
